<dx-data-grid class="gridContainer box-shadow" #gridActualStock [dataSource]="edit.data" [columnAutoWidth]="true"
	[allowColumnReordering]="true" [height]="ui.fullHeight" [remoteOperations]="true"
	(onCellPrepared)="onCellPreparedEv($event)"
	(onToolbarPreparing)="toolbarPreparing($event, false, true)">

	<dxo-export [enabled]="ui.exportEnabled" fileName="actualStock"></dxo-export>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
	</dxo-state-storing>
	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>

	<dxi-column [caption]="L('WORK_CENTER')" dataField="currentWorkCenter" dataType="string"></dxi-column>
	<dxi-column [caption]="L('DATE')" dataField="date" dataType="date" [calculateDisplayValue]="iui.formatDate"
		calculateSortValue="date" width="120">
	</dxi-column>
	<dxi-column [caption]="L('GENERATOR')" dataField="company" dataType="string"></dxi-column>
	<dxi-column [caption]="L('GENERATOR_WORKSTATION')" dataField="workCenter" dataType="string"></dxi-column>
	<dxi-column [caption]="L('WASTE_CODE')" dataField="wasteCode" dataType="string"></dxi-column>
	<dxi-column [caption]="L('WASTE_NAME')" dataField="wasteName" dataType="string"></dxi-column>
	<dxi-column [caption]="L('U')" dataField="wasteUom" dataType="number">
		<dxo-lookup [dataSource]="proxy.wasteType.uom" valueExpr="id" displayExpr="value"> </dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('TYPE')" dataField="typeId" dataType="number">
		<dxo-lookup [dataSource]="edit.type" valueExpr="id" displayExpr="value"></dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('ENTRY')" dataField="entry" dataType="number"></dxi-column>
	<dxi-column [caption]="L('REMOVED')" dataField="eliminated" dataType="number"></dxi-column>
	<dxi-column [caption]="L('TREATED')" dataField="treated" dataType="number"></dxi-column>
	<dxi-column [caption]="L('TAKEN')" dataField="taken" dataType="number"></dxi-column>
	<dxi-column [caption]="L('QUANTITY_LEFT')" dataField="remain" dataType="number"></dxi-column>
	<dxi-column [caption]="L('OBSERVATIONS')" dataField="observations" dataType="string"></dxi-column>

	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>

	<dxo-summary>
		<dxi-total-item column="entry" summaryType="sum" displayFormat="Total: {0}"
			[valueFormat]="{type:'fixedPoint', precision: 6 }"></dxi-total-item>
		<dxi-total-item column="eliminated" summaryType="sum" displayFormat="Total: {0}"
			[valueFormat]="{type:'fixedPoint', precision: 6 }"></dxi-total-item>
		<dxi-total-item column="treated" summaryType="sum" displayFormat="Total: {0}"
			[valueFormat]="{type:'fixedPoint', precision: 6 }"></dxi-total-item>
		<dxi-total-item column="taken" summaryType="sum" displayFormat="Total: {0}"
			[valueFormat]="{type:'fixedPoint', precision: 6 }"></dxi-total-item>
		<dxi-total-item column="remain" summaryType="sum" displayFormat="Total: {0}"
			[valueFormat]="{type:'fixedPoint', precision: 6 }"></dxi-total-item>
	</dxo-summary>
</dx-data-grid>