<dx-form #partyForm [formData]='edit.model' [showValidationSummary]="false" labelLocation="left" [colCount]='2'
	[validationGroup]="edit.validation">

	<dxi-item [label]="{text: L('ENT_WORKC') }" dataField="workCenterId" [colSpan]='2' editorType="dxSelectBox"
		[editorOptions]="{dataSource: proxy.party.ownWorkCenter, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true,
		onValueChanged: onWorkCenterChanged, disabled: (edit.model.id != undefined || edit.model.id != null)}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>

	<dxi-item [label]="{text: L('DATE') }" dataField="date" editorType="dxDateBox"
		[editorOptions]="{ width: '100%', displayFormat: 'dd.MM.yyyy' }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('CARRIER') }" dataField="transporterId" editorType="dxSelectBox" [editorOptions]="{ dataSource: proxy.transporter.name, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true,
		onValueChanged: onTransporterChanged, disabled: (edit.model.id != undefined || edit.model.id != null) }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>

	<dxi-item [label]="{text: L('REGISTER_NO') }" dataField="carId" editorType="dxSelectBox" [editorOptions]="{ dataSource: proxy.transporter.car, valueExpr: 'id', displayExpr: 'registerNo',
		disabled: !edit.model.transporterId, searchEnabled: true, onInitialized: onCarInit }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('DRIVER') }" dataField="driverId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.transporter.driver, valueExpr: 'id', displayExpr: proxy.transporter.displayDriverName,
		disabled: !edit.model.transporterId, searchEnabled: true, searchExpr:['firstName','lastName'], onInitialized: onDriverInit }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>

	<dxi-item [label]="{text: L('GENERATOR') }" dataField="partyWorkCenterId" editorType="dxSelectBox" [colSpan]='2'
		[editorOptions]="{ dataSource: proxy.party.otherGeneration, valueExpr: 'c_id', displayExpr: 'fullName', 
		onValueChanged: onGeneratorChanged, searchEnabled: true, 
		disabled: (edit.model.id != undefined && edit.model.id != null) }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('WASTE_CODE') }" dataField="wasteTypeId" editorType="dxSelectBox" [editorOptions]="{ 
		dataSource: proxy.wasteType.code, valueExpr: 'id', displayExpr: 'code',
		onInitialized: onWasteTypeInit, onValueChanged: onWasteTypeChanged, searchEnabled: true, 
		disabled: (!edit.model.workCenterId || edit.model.id != undefined || edit.model.id != null) }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('QUANTITY') }" [helpText]="qtyHelpText" dataField="quantity" editorType="dxNumberBox"
		[helpText]="qtyHelpText" [editorOptions]="{ readOnly: (edit.model.id != undefined && edit.model.id != null),
		buttons: [{name: 'uom', location: 'after', options: uomHelper.options }] }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="pattern" pattern="^\d+(\.\d{0,6})?$" [message]="L('DECIMAL_VALIDATION')">
		</dxi-validation-rule>
		<dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="validateQuantity">
		</dxi-validation-rule>
	</dxi-item>


	<dxi-item [label]="{text: L('FAT') }" dataField="approvalId" editorType="dxSelectBox" [helpText]="L('FAT_HELP')"
		[editorOptions]="{ dataSource: proxy.transportApproval.code, 
			valueExpr: 'ta_id', displayExpr: 'fullName', disabled: !edit.requiredFAT, showClearButton: true, searchEnabled: true, onInitialized: onFATInit,
			onValueChanged: onFatChanged }">
		<dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="validateFAT"
			[message]="L('FAT_IS_RQUIRED')">
		</dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: edit.model.hazard == 0 ? L('FID') : L('FET') }" dataField="fet" editorType="dxTextBox"
		[editorOptions]="{ disabled: !edit.model.wasteTypeId}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('ELIMINATION_CODE')}" dataField="eliminationId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.wasteType.eliminationCode, valueExpr: 'id', displayExpr: 'description', showClearButton: true, searchEnabled: true}">
		<dxi-validation-rule type="custom" [reevaluate]='true' [validationCallback]="validateCode"
			[message]="L('ELIMINATION_CAPITALIZATION_REQUIRED')">
		</dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('CAPITALIZATION_CODE')}" dataField="capitalizationId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.wasteType.capitalizationCode, valueExpr: 'id', displayExpr: 'description', showClearButton: true, searchEnabled: true}">
		<dxi-validation-rule type="custom" [reevaluate]='true' [validationCallback]="validateCode"
			[message]="L('ELIMINATION_CAPITALIZATION_REQUIRED')">
		</dxi-validation-rule>
	</dxi-item>
	<!-- TODO: @Luca check if we need this-->
	<!-- <dxi-item [label]="{text: L('ELIMINATION_TYPE')}" [colSpan]='1'>
                            <div *dxTemplate='let data of "template"'>
                                <dx-drop-down-box #actionDropdown [(value)]='edit.model.selectedActions'
                                    displayExpr='value' valueExpr='id' [dataSource]="edit.transportActions"
                                    [placeholder]='L("SELECT")'>
                                    <div *dxTemplate='let actions of "content"'>
                                        <dx-list [dataSource]="dsFullTransportActions" selectionMode="multiple"
                                            [showSelectionControls]='true' keyExpr='id' displayExpr='value'
                                            [(selectedItemKeys)]='edit.model.selectedActions'
                                            (onSelectionChanged)='onActionListChange($event, actionDropdown)'>
                                        </dx-list>
                                    </div>
                                    <dx-validator validationGroup="customTValidation">
                                        <dxi-validation-rule type="required"></dxi-validation-rule>
                                    </dx-validator>
                                </dx-drop-down-box>
                            </div>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-item> -->
	<!-- END TODO -->
	<dxi-item itemType='empty'></dxi-item>
	<dxi-item [label]="{text: L('PHYSICAL_STATE')}" dataField="physicalCondition" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.wasteType.physicalState, valueExpr: 'id', displayExpr: 'value'}">
	</dxi-item>

	<dxi-item [label]="{text: L('INVOICE_NO')}" dataField="invoiceNo" editorType="dxTextBox" [colSpan]="1"
		[editorOptions]="{}">
	</dxi-item>

	<dxi-item [label]="{text: L('INVOICE_DATE')}" dataField="invoiceDate" [colSpan]="1" editorType="dxDateBox"
		[editorOptions]="{ width: '100%', displayFormat: 'dd.MM.yyyy'}">
	</dxi-item>


	<dxi-item [label]="{text: L('WASTE_TYPE')}" dataField="wasteType.hazardName" editorType="dxTextBox" [colSpan]="2"
		[editorOptions]="{ readOnly: true }">
	</dxi-item>
	<dxi-item [label]="{text: L('WASTE_DESCRIPTION')}" dataField="wasteType.name" editorType="dxTextBox" [colSpan]='2'
		[editorOptions]="{readOnly: true }">
	</dxi-item>
	<dxi-item [label]="{text: L('OBSERVATIONS')}" dataField="observations" editorType="dxTextArea" [colSpan]="2">
	</dxi-item>
</dx-form>