<dx-data-grid #gridGenerate class="gridContainer" [dataSource]='edit.data'
	[remoteOperations]='true' [height]='ui.fullHeight'
	(onToolbarPreparing)='toolbarPreparing($event)'>

	<dxo-export [enabled]='ui.exportEnabled' fileName='generate'></dxo-export>
	<dxo-scrolling mode='infinite' [showScrollbar]='ui.showScrollbar' [useNative]='!ui.isWindows'>
	</dxo-scrolling>
	<dxo-state-storing [enabled]='ui.stateStoringEnabled' type="localStorage" storageKey="qhseGenerate">
	</dxo-state-storing>

	<dxi-column type="buttons" [width]="120" fixedPosition="left" [fixed]="true" [allowResizing]="false"
		[showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]='L("TRANSPORT")' icon='car' [onClick]="onTransportClick"></dxi-button>
		<dxi-button [hint]='L("TRANSFER")' icon='redo' [onClick]="onTransferClick"></dxi-button>
		<dxi-button [hint]='L("EDIT")' icon="edit" [onClick]="onEditClick" [visible]='proxy.allowEdit'>
		</dxi-button>
		<dxi-button [hint]='L("DELETE")' icon='trash' [onClick]="onDeleteClick" [visible]='proxy.allowDelete'>
		</dxi-button>
	</dxi-column>
	<dxi-column [caption]='L("WORK_CENTER")' dataField='wc_name' dataType='string'>
	</dxi-column>
	<dxi-column [caption]='L("WASTE_CODE")' dataField='wt_code' dataType='string'></dxi-column>
	<dxi-column [caption]='L("WASTE_NAME")' dataField='wt_name' dataType='string'></dxi-column>
	<dxi-column [caption]='L("PHYSICAL_STATE")' dataField='g_physicalCondition' dataType='string'>
		<dxo-lookup [dataSource]='proxy.wasteType.physicalState' valueExpr='id' displayExpr='value'> </dxo-lookup>
	</dxi-column>
	<dxi-column [caption]='L("U")' dataField='uom' dataType='number'>
		<dxo-lookup [dataSource]='proxy.wasteType.uom' valueExpr='id' displayExpr='value'> </dxo-lookup>
	</dxi-column>
	<dxi-column [caption]='L("QUANTITY")' dataField='g_quantity' dataType='number'></dxi-column>
	<dxi-column [caption]='L("OPERATION_DATE")' dataField='g_date' dataType='date' [width]='120'
		calculateSortValue='g_date' [calculateDisplayValue]='iui.formatDate'>
	</dxi-column>
	<dxi-column [caption]='L("ELIMINATION_CODE")' dataField='e_code' dataType='string'></dxi-column>
	<dxi-column [caption]='L("CAPITALIZATION_CODE")' dataField='cap_code' dataType='string'></dxi-column>
	<dxi-column [caption]='L("OBSERVATIONS")' dataField='g_observations' dataType='string'></dxi-column>
	
	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
</dx-data-grid>

<ibis-edit-popup [edit]='edit' [showAdd]='true'>
    <app-ecofire-generate-edit></app-ecofire-generate-edit>
</ibis-edit-popup>