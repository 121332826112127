<dx-data-grid #gridFullTransport class="gridContainer" [remoteOperations]="true"
	[height]="ui.fullHeight" [dataSource]="edit.data" (onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)">

	<dxo-export [enabled]="ui.exportEnabled"></dxo-export>
	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
	</dxo-state-storing>
	<dxo-column-fixing [enabled]="true"></dxo-column-fixing>

	<dxi-column type="buttons" [minWidth]="actionsWidth" [width]="actionsWidth" fixedPosition="left" [fixed]="true"
		[allowResizing]="false" [showInColumnChooser]="false">
		<dxi-button [hint]="L('NOTICE')" icon="doc" [onClick]="onNoticeClick"> </dxi-button>
		<dxi-button [hint]="L('ANNEX')" icon="fieldchooser" [onClick]="onAnnexClick"></dxi-button>
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit"></dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
		</dxi-button>
	</dxi-column>

	<dxi-column name='sender' headerCellTemplate='senderHeader'>
		<dxi-column [caption]="L('NO')" dataField="t_no" dataType="string"></dxi-column>
		<dxi-column [caption]="L('ENT_WORKC')" dataField="fullName" dataType="string"></dxi-column>
		<dxi-column [caption]="L('SENT_DATE')" dataField="t_sendDate" dataType="date"
			calculateSortValue="t_sendDate" [calculateDisplayValue]="iui.formatDate"></dxi-column>
	</dxi-column>
	<dxi-column name='transporter' headerCellTemplate='transHeader'>
		<dxi-column [caption]="L('LADING_BILL')" dataField="t_ladingBill" dataType="string"></dxi-column>
		<dxi-column [caption]="L('CARRIER')" dataField="tr_name" dataType="string"></dxi-column>
		<dxi-column [caption]="L('TRANSPORT_TYPE')" dataField="t_transportTypeId" dataType="string">
			<dxo-lookup [dataSource]="edit.type" valueExpr="id" displayExpr="description"></dxo-lookup>
		</dxi-column>
		<dxi-column [caption]="L('REGISTER_NO')" dataField="car_registerNo" dataType="string"></dxi-column>
		<dxi-column [caption]="L('DRIVER')" dataField="driverName" dataType="string"></dxi-column>
	</dxi-column>
	<dxi-column name="recipient" headerCellTemplate="reciHeader">
		<dxi-column [caption]="L('RECIPIENT')" dataField="rt_name" dataType="string"></dxi-column>
		<dxi-column [caption]="L('TYPE')" dataField="t_destinationTypeId" dataType="string">
			<dxo-lookup [dataSource]="edit.destinationType" valueExpr="id" [displayExpr]="edit.enumDisplay"></dxo-lookup>
		</dxi-column>
	</dxi-column>
	<dxi-column name='waste' headerCellTemplate='wasteHeader'>
		<dxi-column [caption]="L('WASTE_CODE')" dataField="wt_code" dataType="string"></dxi-column>
		<dxi-column [caption]="L('QUANTITY')" dataField="t_quantity" dataType="number"></dxi-column>
		<dxi-column [caption]="L('U')" dataField="uom" dataType="number">
			<dxo-lookup [dataSource]="proxy.wasteType.uom" valueExpr="id" displayExpr="value"></dxo-lookup>
		</dxi-column>
		<dxi-column [caption]="L('PIECE')" dataField="t_piece" dataType="number"></dxi-column>
		<dxi-column [caption]="L('PACKING_MATERIAL')" dataField="m_description" dataType="string"></dxi-column>
	</dxi-column>
	<dxi-column [caption]="L('OBSERVATIONS')" dataField="t_observations" dataType="string"></dxi-column>
	<dxi-column name='audit' headerCellTemplate='auditHeader'>
		<dxi-column [caption]="L('MADE_BY')" dataField="ti_createdBy" dataType="string"></dxi-column>
		<dxi-column [caption]="L('MODIFIED_BY')" dataField="ti_modifiedBy" dataType="string"></dxi-column>
		<dxi-column [caption]="L('CREATION_DATE')" dataField="ti_created" dataType="datetime"
			calculateSortValue="ti_created" [calculateDisplayValue]="iui.formatDateTime"></dxi-column>
		<dxi-column [caption]="L('LAST_MODIFIED')" dataField="ti_modified" dataType="datetime"
			calculateSortValue="ti_modified" [calculateDisplayValue]="iui.formatDateTime"></dxi-column>
	</dxi-column>

	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
	<div *dxTemplate="let c of 'senderHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-file"></i> {{ L('SOURCE') }}
	</div>
	<div *dxTemplate="let c of 'transHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-truck-moving"></i> {{ L('TRANSPORT') }}
	</div>
	<div *dxTemplate="let c of 'reciHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class='fas fa-map-marker-alt'></i> {{ L('DESTINATION') }}
	</div>
	<div *dxTemplate="let c of 'wasteHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class='fas fa-recycle'></i> {{ L('WASTE') }}
	</div>
	<div *dxTemplate="let c of 'auditHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class='far fa-calendar-alt'></i> {{ L('AUDIT') }}
	</div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-transport-full-edit></app-transport-full-edit>
</ibis-edit-popup>